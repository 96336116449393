@import "../src/fonts/fonts.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "TT Commons Regular", sans-serif;
}

ul {
  list-style: none;
}

.form-control {
  color: #1f2349 !important;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

#webpack-dev-server-client-overlay-div {
  display: none !important;
}