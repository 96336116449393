.img_modal .modal-content {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 9px 38px 0px #8087c4;
  padding: 1rem;
}
.img_modal .modal-header {
  padding: 0 !important;
}
.img_modal .modal-body {
  position: relative;
}
.img_modal .modal-body .zoomed_image_previous_btn,
.img_modal .modal-body .zoomed_image_next_btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  color: #bdcaf7;
  font-size: 3rem;
  border: none;
}
.img_modal .modal-body .zoomed_image_next_btn:focus,
.img_modal .modal-body .zoomed_image_next_btn:hover {
  border: none;
  right: -1.6rem;
}
.img_modal .modal-body .zoomed_image_previous_btn:hover,
.img_modal .modal-body .zoomed_image_previous_btn:focus {
  border: none;
  left: -1.6rem;
}
.img_modal .modal-body .zoomed_image_previous_btn {
  left: -1.5rem;
}
.img_modal .modal-body .zoomed_image_next_btn {
  right: -1.5rem;
}

.zoomed_image {
  border-radius: 15px;
}
