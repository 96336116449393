.footer_container {
  background: rgba(31, 35, 73, 1);
  z-index: 5;
  height: 100%;
}
.inner_footer_box {
  width: 90%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copywrite {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  background: rgba(18, 22, 62, 1);
}
.copywrite_inner_box {
  width: 90%;
  height: 10%;
  text-align: right;
}
.copywrite_inner_box > p {
  color: #57bec0;
  font-size: 14px;
   font-family: 'TT Commons Medium', sans-serif;
  
  margin: 0;
}
.footer_links {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.footer_links > a{
color: #57bec0;
font-size: 15px;
 font-family: 'TT Commons Medium', sans-serif;
text-decoration: none;
}
.footer_links > a:hover {
  color: white;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer_links {
    width: 95%;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0rem;

  }
  .inner_footer_box {
    flex-direction: column;
    height: 80%;
    width: 100%;
    justify-content: space-around;
  }
  .copywrite {
    height: 20%;
  }
  .copywrite_inner_box {
    text-align: center;
  }
}
@media (min-width: 550px) and (max-width: 767px) {
  .footer_links {
    width: 70%;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0rem;

  }
}