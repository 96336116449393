layout styles

.ticketdash_container {
  height: 100%;
  width: auto;
}

@media (min-width: 1440px) {
  .ticketdash_content {
    width: 88%;
    height: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .ticketdash_content {
    width: 87%;
    height: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .ticketdash_content {
    width: 84%;
    height: 100%;
  }
}

@media (max-width: 475px) {
  .ticketdash_container .eventdash_sidebar .eventdash_sidebar_list_element {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .ticketdash_container
    .eventdash_sidebar
    .eventdash_sidebar_list_element:nth-last-of-type(2) {
    padding: 1.5rem 0;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .ticketdash_container {
    height: 100%;
    display: flex;
    border-top-left-radius: 2rem !important;
  }
}

@media (max-width: 1024px) {
  .ticketdash_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 2rem !important;
    text-align: center;
  }
}

.tickets_filter .rmdp-container {
  width: 100%;
}

/* tickets list styles */

.tickets_list ._filters_row {
  display: flex;
  border-radius: 20px;
  background: #fff;
  padding: 0.7rem 0;
}

.tickets_list ._filters_row ._row_icon {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tickets_list ._filters_row ._filter {
  width: calc(93% / 10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
  border-right: 1px solid #bdcaf7;
  padding-left: 0.8rem;
  padding-right: 0.2rem;
}

.tickets_list ._filters_row ._filter ._label {
  color: #889bff;
  font-size: 0.6rem;
   font-family: 'TT Commons Bold', sans-serif;
  text-transform: uppercase;
}

.tickets_list ._filters_row ._filter:nth-of-type(10),
.tickets_list ._filters_row ._filter:nth-of-type(11) {
  justify-content: center;
}

.tickets_list ._filters_row ._filter:last-of-type {
  border-right: none;
}

/* temporary container */
/* sign-up modal style */

@media (max-width: 576px) {
  .signup_modal .modal-body,
  .signup_modal .modal-footer {
    padding: 0 5rem;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .signup_modal .modal-dialog {
    max-width: 80%;
  }

  .signup_modal .modal-body,
  .signup_modal .modal-footer {
    padding: 0 5rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .signup_modal .modal-dialog {
    max-width: 60%;
  }

  .signup_modal .modal-body,
  .signup_modal .modal-footer {
    padding: 0 5rem;
  }
}

@media (min-width: 992px) and (max-width: 1700px) {
  .signup_modal .modal-dialog {
    max-width: 35%;
  }

  .signup_modal .modal-body,
  .signup_modal .modal-footer {
    padding: 0 5rem;
  }
}

@media (min-width: 1700px) {
  .signup_modal .modal-dialog {
    max-width: 30%;
  }

  .signup_modal .modal-body,
  .signup_modal .modal-footer {
    padding: 0 6rem;
  }
}

.signup_modal .modal-content {
  border-radius: 40px !important;
  background: #fff !important;
}

.signup_modal .btn-close {
  background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.30723 11.7757C1.00818 12.0748 0.523328 12.0748 0.224288 11.7757C-0.0747625 11.4767 -0.0747625 10.9918 0.224288 10.6928L4.9171 6L0.224288 1.30723C-0.0747625 1.00818 -0.0747625 0.523328 0.224288 0.224288C0.523328 -0.0747625 1.00818 -0.0747625 1.30723 0.224288L6 4.9171L10.6928 0.224288C10.9918 -0.0747625 11.4767 -0.0747625 11.7757 0.224288C12.0748 0.523328 12.0748 1.00818 11.7757 1.30723L7.0829 6L11.7757 10.6928C12.0748 10.9918 12.0748 11.4767 11.7757 11.7757C11.4767 12.0748 10.9918 12.0748 10.6928 11.7757L6 7.0829L1.30723 11.7757Z" fill="%2364C3C5"/></svg>') !important;
  background-color: #d5eeef;
  opacity: 1;
  margin: 0;
  align-self: flex-end;
}

.signup_modal .modal-header {
  flex-direction: column-reverse;
  border-bottom: none;
}

.signup_modal .modal-title {
  width: 100%;
  color: #1f2349;
  text-align: center;
  font-size: 2.5rem;
   font-family: 'TT Commons Bold', sans-serif;
  line-height: 165.523%;
}
/* 
.signup_modal ._input_group .input-group-text {
  background-color: #fff !important;
  border: 1px solid #9fafe7 !important;
  border-right: none !important;
  border-radius: 80px;
} */

.signup_modal ._input_group .form-control {
  background-color: #fff !important;
  border: 1px solid #9fafe7 !important;
  border-left: none !important;
  border-radius: 80px;
  padding: 0.7rem 1rem;
}

.signup_modal .modal-footer {
  border-top: none;
}

.signup_modal .modal-footer .signup_btn {
  width: 100%;
  border-radius: 80px;
  background: #444790;
  padding: 0.7rem 1rem;
  font-size: 1.3rem;
  color: #fff;
}

.signup_modal .modal-footer .signup_notif {
  color: #444790;
   font-family: 'TT Commons Regular', sans-serif;
}

.signup_modal .modal-footer .signup_notif ._link {
  color: #57bec0;
}
