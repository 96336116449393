@media (min-width: 1024px) and (min-height: 900px) {
  .event_card_outerbox {
    height: 63%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 1024px) and (max-height: 750px) {
  .event_card_outerbox {
    height: 81%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 1024px) and (min-height: 750px) and (max-height: 800px) {
  .event_card_outerbox {
    height: 76%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}
@media (min-width: 1024px) and (min-height: 800px) and (max-height: 900px) {
  .event_card_outerbox {
    height: 71%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 576px) and (max-width: 768px) and (max-height: 750px) {
  .event_card_outerbox {
    height: 87%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 576px) and (max-width: 768px) and (min-height: 750px) and (max-height: 800px) {
  .event_card_outerbox {
    height: 80%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 576px) and (max-width: 768px) and (min-height: 800px) and (max-height: 850px) {
  .event_card_outerbox {
    height: 75%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 576px) and (max-width: 768px) and (min-height: 850px) and (max-height: 900px) {
  .event_card_outerbox {
    height: 70%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 576px) and (max-width: 768px) and (min-height: 900px) {
  .event_card_outerbox {
    height: 65%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (max-height: 750px) {
  .event_card_outerbox {
    height: 92%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (min-height: 750px) and (max-height: 800px) {
  .event_card_outerbox {
    height: 85%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (min-height: 800px) and (max-height: 850px) {
  .event_card_outerbox {
    height: 78%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (min-height: 850px) and (max-height: 900px) {
  .event_card_outerbox {
    height: 75%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (min-height: 900px) {
  .event_card_outerbox {
    height: 60%;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 576px) and (max-height: 700px) {
  .event_card_outerbox {
    height: 110% !important;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 576px) and (min-height: 700px) and (max-height: 750px) {
  .event_card_outerbox {
    height: 100% !important;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 576px) and (min-height: 750px) and (max-height: 800px) {
  .event_card_outerbox {
    height: 93% !important;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 576px) and (min-height: 800px) and (max-height: 850px) {
  .event_card_outerbox {
    height: 90% !important;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 576px) and (min-height: 850px) {
  .event_card_outerbox {
    height: 85% !important;
    padding: 1rem;
    margin-bottom: 0.5%;
  }
}

.event_card_innerbox {
  border-radius: 40px;
  background: #fff;
  height: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .organizer_img {
    position: absolute;
    top: 40%;
    right: 5%;
    transform: translateY(-50%);
    max-width: 50px;
  }
}

@media (max-width: 576px) {
  .organizer_img {
    position: absolute;
    top: 40%;
    right: 5%;
    transform: translateY(-50%);
    max-width: 65px;
  }
}

.event_card_buttons {
  align-self: flex-end;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.event_card_bg {
  height: 40%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  position: relative;
}

.event_card_status {
  border-radius: 0px 65.055px 65.055px 0px;
  background: #fff;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  padding: 0.3rem 1rem 0.3rem 0.5rem;
}

.event_card_details {
  height: 60%;
  padding-left: 3rem;
}

@media (min-width: 576px) {
  .event_name {
    color: #444790;
     font-family: 'TT Commons Bold', sans-serif;
    font-size: 1.2rem;
  }

  .event_online_status {
    border-radius: 50px;
    background: #64c3c5;
    padding: 0.1rem 0.5rem;
    width: fit-content;
    font-size: 0.8rem;
  }

  .event_recurring {
    font-size: 0.8rem;
  }

  .detail_text {
    font-size: 0.6rem;
    margin-left: 0.5rem;
    color: #1f2349;
  }
}

@media (max-width: 576px) {
  .event_name {
    color: #444790;
     font-family: 'TT Commons Bold', sans-serif;
    font-size: 1.5rem;
  }

  .event_online_status {
    border-radius: 50px;
    background: #64c3c5;
    padding: 0.1rem 0.5rem;
    width: fit-content;
    font-size: 0.9rem;
  }

  .event_recurring {
    font-size: 0.8rem;
  }

  .detail_text {
    font-size: 0.6rem;
    margin-left: 0.5rem;
    color: #1f2349;
  }
}
