
  #lineaboutus1FAQ {
    height: 3px;
    width: 46%;
    color: white;
    z-index: 30000;
    margin: auto;
    text-align: left;
    margin-left: 0;
  }
  #lineaboutus2FAQ {
    height: 3px;
    width: 46%;
    color: white;
    z-index: 30000;
    margin: auto;
    text-align: left;
    margin-left: 0;
  }
  .outerfaq {
    position: relative;
    z-index: 100;
    /* height: 500px; */
  }
  
  .wrapper {
    display: flex;
    padding-bottom: 40px;
    width: 100%;
    color: white;
  }
  
  .accordion {
    width: 100%
  }
  
  .item {
    background: rgba(78, 89, 204, 0.05);
    margin-bottom: 5px;
    padding: 10px 20px;
    border-radius: 10px;
  }
  .ac_title {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .ac_title > span {
    color: rgba(68, 71, 144, 1);
    font-size: 20px;
     font-family: 'TT Commons Regular', sans-serif;
  }
  .faq_question {
    font-size: 17px;
     font-family: 'TT Commons Regular', sans-serif;
    text-align: left;
    color: #444790;
    margin: 0;
  }
  
  .contentt {
    color: #c0c0c2;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    text-align: left;
    margin-top: 6px;
    font-size: 14px;
  }
  
  .contentt.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    text-align: left;
    color: black;
    
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    /* .outerfaq {
      height: 42rem;
    } */
  }